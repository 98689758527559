import React, { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import logo from "../images/Pillzer LogoNew.png";
import heroImage from "../images/medical-people-collection-collage.jpg";
import FDAlogo from "../images/istockphoto-1369753139-612x612.jpg";
import CDSCOlogo from "../images/1536257006-9131.png";
import Customer from "../images/istockphoto-1335296686-612x612.jpg";
import social from "../images/facebook-instagram-twitter-and-whatsapp-icon-free-vector.jpg";
import { Row, Col } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";

const AboutUs = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  useEffect(() => {
    return () => {
      //   document.removeEventListener("click", handleOutsideClick);
      //   window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ overflowX: "hidden", padding: "5px" }}>
      {/* Header */}
      <Row>
        <header
          style={{
            backgroundColor: "white",
            padding: "10px",
            color: "#3a57e8",
            display: showHeader ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            marginBottom: "20px",
            // position: "fixed",
            width: "100%",
            zIndex: 1000,
          }}
        >
          {/* Logo and Navigation for larger screens */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={scrollToTop}
          >
            <img
              src={logo}
              alt="Pillzer Health Logo"
              style={{
                maxWidth: "200px",
                maxHeight: "200px",
                marginRight: "10px",
              }}
            />
          </div>
          <nav>
            <ul style={{ listStyle: "none", display: "flex", gap: "15px" }}>
              <li>
                <ScrollLink
                  to="about-section"
                  spy={true}
                  smooth={true}
                  duration={400}
                  offset={11}
                  style={{
                    textDecoration: "none",
                    color: "rgb(36 50 93)",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  About
                </ScrollLink>
              </li>
              <li>
                {/* <ScrollLink
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  duration={400}
                  offset={70}
                  style={{
                    textDecoration: "none",
                    color: "#414146",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Contact
                </ScrollLink> */}
                <Link
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    color: "rgb(36 50 93)",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      </Row>

      <Row>
        <Col lg="12">
          <section>
            <h1 style={{ fontSize: "70px" }}>
              Quality Healthcare for Better Future
            </h1>
            <br />
            <img
              src={heroImage}
              alt="Diverse group of individuals smiling, doctor with a patient"
              style={{
                width: "100%",
                height: "auto",
                margin: "20px auto",
              }}
            />
          </section>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <section>
            <h2>Mission and Vision</h2>
            <p>
              <b style={{ color: "rgb(36 50 93)" }}>Mission-</b>Empowering
              healthier generations through innovative healthcare solutions,
              ensuring a brighter, healthier future for all.
            </p>
            <p>
              <b style={{ color: "rgb(36 50 93)" }}>Vission-</b>Driven by a
              commitment to excellence, we elevate healthcare standards through
              continuous innovation and compassionate care, providing each and
              every individual with quality healthcare consistently.
            </p>
          </section>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <section style={{ textAlign: "center" }}>
            <h2>Collaborations</h2>
            <div>
              <img
                src={FDAlogo}
                alt="FDA Logo"
                style={{
                  width: "120px",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              />
              <img
                alt="CDSCO Logo"
                src={CDSCOlogo}
                style={{ width: "150px", display: "inline-block" }}
              />
            </div>
          </section>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <section>
            <h2>Why Quality Medicines are Important ?</h2>
            <p>
              Have you noticed that some medicines work right away while others
              don't, even if they have a similar composition? This is because of
              the quality of the medicines. High-quality drugs will be more
              effective, have fewer side effects, and act faster, whereas
              low-quality products can do more harm than good to the body. They
              could even damage the liver, kidneys, or DNA, leading to genetic
              mutations and cancer with long-term use.
            </p>
            <p>
              So Pillzer created an innovative platform to make ONLY the highest
              quality pills available to people. You can be assured that every
              pill in your PILLZER prescription has been lab tested, regulated,
              and approved - these are the best quality drugs which shorten your
              treatment time, aid faster recovery, and help you resume your
              loved activities sooner.
            </p>
          </section>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <section id="about-section">
            <h2>About Us</h2>
            <p>
              Health is deeply personal, and we understand the trust you place
              in us when it comes to your well-being. At PILLZER, we pour our
              hearts into ensuring that every PILLZER prescription generated is
              more than just a list of pills — it's a promise of authenticity,
              care, and reliability. We're a team driven by the passion to
              deliver not just a prescription with QUALITY medicines but a sense
              of security and comfort.
            </p>
            <p>
              Behind every pill and every prescription lies a commitment to your
              health, backed by rigorous quality checks and unwavering adherence
              to stringent regulatory standards ensuring top-notch authenticity.
              We want you to feel empowered and confident in your choice. That's
              why we've taken steps to make sure your prescriptions are easily
              verifiable—look for the unique identification codes or QR scans.
            </p>
            <p>
              {" "}
              Moreover, we encourage you to engage with us, ask questions, and
              seek the assurance you deserve. Your trust isn't just earned; it's
              cherished and honored every single day. Your well-being remains at
              the heart of everything we do.
            </p>
          </section>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <section style={{ textAlign: "center" }}>
            <h2>Testimonials</h2>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "45%",
                  marginBottom: "20px",
                  padding: "15px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={Customer}
                  alt="Customer 1"
                  style={{
                    width: "100%",
                    borderRadius: "50%",
                    marginBottom: "10px",
                  }}
                />
                <p style={{ fontStyle: "italic", color: "#555" }}>
                  "I had a great experience with your product. It exceeded my
                  expectations!"
                </p>
              </div>
              <div
                style={{
                  width: "45%",
                  marginBottom: "20px",
                  padding: "15px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={Customer}
                  alt="Customer 2"
                  style={{
                    width: "100%",
                    borderRadius: "50%",
                    marginBottom: "10px",
                  }}
                />
                <p style={{ fontStyle: "italic", color: "#555" }}>
                  "Your team is amazing! They provided excellent support
                  throughout the process."
                </p>
              </div>
            </div>
          </section>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <section
            id="contact-section"
            style={{
              textAlign: "center",
            }}
          >
            <center>
              <span
                style={{
                  color: "rgb(0 23 82)",
                  textAlign: "center",
                }}
              >
                Get in Touch for a Free subscription of worth Rs.500/-
              </span>
            </center>
            <br />

            <h2>Social Media</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <img src={social} alt="Facebook" style={{ width: "200px" }} />
              {/* Add more social media icons as needed */}
            </div>
          </section>
        </Col>
      </Row>

      <Footer />
    </div>
  );
};

export default AboutUs;
