import React, { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "20px", paddingTop: "0", overflowX: "hidden" }}>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "white" }}>Contact Us</h1>
      </div>
      <br />
      <span>
        <strong>PillzerHealth</strong>
        <br />
        A unit of 21ventures Healthtech Private Limited <br />
        <strong>CIN- </strong>U33100KA2022PTC165221
        <br />
        <strong>address:</strong>
        No 3, 2nd floor, 4th A cross, pipeline road,
        <br />
        Vijayanagar, Bengaluru, Karnataka - 560040
      </span>
      <br />
      <span>
        <strong>phone:</strong> 8989607090
        <br />
        <strong>mail:</strong>{" "}
        <a href="mailto:myhealth@pillzer.health">myhealth@pillzer.health</a>
      </span>
      <br />
      <br />
      <span>
        <strong>Customer Support:</strong>
        <br />
        You may mail at us for any queries or assistance related to your orders:
        <br />
        <a href="mailto:myhealth@pillzer.health">myhealth@pillzer.health</a>
      </span>
      <br />
      <span>
        <strong>For Corporate Inquiries:</strong>
        <br />
        Mail us at: <a href="mailto:ask@pillzer.health">ask@pillzer.health</a>
      </span>
      <br />
      <br />

      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
          //   position: "relative",
          bottom: 0,
          width: "100%",
          paddingRight: "20px", // Add this line for right padding
        }}
      >
        <h1 style={{ color: "white" }}>PillzerHealth</h1>

        <span style={{ color: "white" }}>
          Copyright © 2023, Pillzer.health. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default ContactUs;
