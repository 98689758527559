import React, { useEffect } from "react";

const PrivacyAndPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "20px", paddingTop: "0" }}>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <h1 style={{ color: "white", fontSize: "2rem", marginBottom: "10px" }}>
          PILLZER (21ventures Healthtech Private Limited)
        </h1>
        <h2 style={{ color: "white", fontSize: "1.5rem", margin: "0" }}>
          Privacy And Policy
        </h2>
      </div>

      <br />
      <span>
        <strong>Privacy Policy</strong>
        <br />
        <span>
          At PILLZER, your privacy is of paramount importance to us. This
          Privacy Policy explains how we collect, use, store, and protect your
          personal information when you interact with our website and services.
          By using our services, you agree to the collection and processing of
          data in accordance with this policy.
        </span>
      </span>
      <br />
      <span>
        <strong>1. Information We Collect</strong>
        <br />
        <ul>
          <li style={{ listStyleType: "none" }}>
            We collect a variety of personal and non-personal information when
            you interact with our website, use our services, or participate in
            surveys, questionnaires, or other activities. This includes:
          </li>
        </ul>
        <span>
          <strong>Personal Information:</strong>
          <ul>
            <li>
              <strong> Basic Identifying Information:</strong> Name, contact
              details (email address, phone number), and shipping/billing
              address.
            </li>
            <li>
              <strong>Demographic Information:</strong> Age, sex, date of birth,
              marital status, nationality, ethnicity, religion, occupation, and
              other personal details.
            </li>
            <li>
              <strong>Government Identification Information:</strong> Details of
              government-issued identification documents provided.
            </li>
            <li>
              <strong>Financial Information:</strong> Payment details such as
              credit/debit card numbers, bank account details, and transaction
              history.
            </li>
            <li>
              <strong>Travel Information:</strong> Travel history or any related
              details shared through surveys, questionnaires, or forms.
            </li>
            <li>
              <strong>Survey/Questionnaire Responses:</strong> Any other
              personal information provided through surveys, questionnaires, or
              similar means.
            </li>
          </ul>
        </span>
        <span>
          <strong>Non-Personal Information:</strong>
          <ul>
            <li>
              Browsing data (such as IP address, device type, browser type, and
              usage patterns)
            </li>
            <li>
              Cookies and tracking technologies (to enhance your user
              experience)
            </li>
          </ul>
        </span>
      </span>

      <span>
        <strong>2. How We Use Your Information</strong>
        <br />
        <span>
          We use the information we collect for the following purposes:
        </span>
        <ul>
          <li>
            <strong>Order Processing:</strong> To fulfill your orders, process
            payments, and complete transactions.
          </li>
          <li>
            <strong>Customer Support:</strong> To respond to your inquiries and
            provide assistance with your orders.
          </li>
          <li>
            <strong>Personalization:</strong> To tailor our website, services,
            and offers based on your preferences.
          </li>
          <li>
            <strong>Surveys and Feedback:</strong> To analyze responses to
            surveys, questionnaires, or feedback forms.
          </li>
          <li>
            <strong>Communication:</strong> To send you order updates,
            promotional materials, newsletters, or offers.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To comply with applicable laws,
            regulations, and legal obligations.
          </li>
          <li>
            <strong>Transaction History:</strong> To maintain records of
            transactions for reference, accounting, and auditing.
          </li>
        </ul>
      </span>

      <span>
        <strong>3. Data Sharing and Disclosure</strong>
        <br />
        <span>
          We respect your privacy and will never sell your personal data to
          third parties. However, we may share your information in the following
          situations:
        </span>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your data with
            third-party vendors who assist with order fulfillment, payment
            processing, website analytics, or customer support services. These
            partners are required to handle your data in accordance with strict
            privacy standards.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may disclose your information
            if required to do so by law or in response to valid requests from
            governmental authorities, such as a court order or legal obligation.
          </li>
        </ul>
      </span>

      <span>
        <strong>4. Security of Your Data</strong>
        <br />
        <span>
          <ul>
            <li>
              We implement a range of security measures to protect your personal
              data, including encryption, firewalls, and secure server
              protocols. However, no method of transmission over the internet or
              electronic storage is 100% secure, and while we strive to protect
              your data, we cannot guarantee absolute security.
            </li>
          </ul>
        </span>
      </span>

      <span>
        <strong>5. Your Rights and Choices</strong>
        <br />
        <span>
          As a customer, you have the right to control your personal data. You
          may:
        </span>
        <ul>
          <li>
            <strong>Access:</strong> Request a copy of the personal data we hold
            about you.
          </li>
          <li>
            <strong>Correct:</strong> Update or correct any inaccuracies in your
            personal data.
          </li>
          <li>
            <strong>Delete:</strong> Request the deletion of your personal data,
            subject to legal obligations.
          </li>
          <li>
            <strong>Opt-Out:</strong> Opt-out of receiving marketing emails or
            notifications at any time by clicking the "unsubscribe" link in our
            communications or by contacting us directly.
          </li>
        </ul>
      </span>

      <span>
        <strong>6. Cookies and Tracking Technologies</strong>
        <br />
        <span>
          <ul>
            <li>
              Our website uses cookies and similar tracking technologies to
              enhance your browsing experience, analyze website traffic, and
              provide targeted advertisements. You can control the use of
              cookies through your browser settings. However, please note that
              disabling cookies may affect certain features of our website.
            </li>
          </ul>
        </span>
      </span>
      <span>
        <strong>7. Third-Party Links</strong>
        <br />
        <span>
          <ul>
            <li>
              Our website may contain links to third-party websites. We are not
              responsible for the privacy practices or content of these external
              sites. We encourage you to review their privacy policies before
              providing any personal information.
            </li>
          </ul>
        </span>
      </span>
      <span>
        <strong>8. Changes to This Privacy Policy</strong>
        <br />
        <span>
          <ul>
            <li>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices, services, or legal requirements. Any
              updates will be posted on this page, and the "Effective Date" at
              the top of the policy will be updated accordingly. We encourage
              you to periodically review this Privacy Policy to stay informed
              about how we protect your information.
            </li>
          </ul>
        </span>
      </span>
      <span>
        <strong>9. Contact Us</strong>
        <br />
        <span>
          <ul>
            <li>
              If you have any questions or concerns about this Privacy Policy or
              how we handle your data, please contact us at &nbsp;
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=myhealth@pillzer.health"
                target="_blank"
              >
                myhealth@pillzer.health
              </a>
            </li>
          </ul>
        </span>
      </span>
      <span>
        <strong>Return Process</strong>
        <br />
        <span>
          At PILLZER, we want to ensure that you are fully satisfied with your
          purchase. If for any reason you need to return a product, please
          follow the steps below:
        </span>
        <ol>
          <li>
            <strong>Return Intimation:</strong> To initiate a return, please
            visit our Contact Us page.
          </li>
          <li>
            <strong>Verification:</strong> Our customer care team will verify
            the details of your return request within 72 business hours from the
            time we receive your complaint.
          </li>
          <li>
            <strong>Product Collection:</strong> Once your return claim is
            verified and approved, we will arrange for the collection of the
            product(s) you wish to return.
          </li>
          <li>
            <strong>Product Condition:</strong> Please note that only unused
            products in their original, unopened packaging will be accepted. We
            are unable to accept returns for products that have been used or
            have tampered seals.
          </li>
          <li>
            <strong>Packaging:</strong> For the return process, kindly pack the
            product(s) in their original manufacturer’s packaging to ensure safe
            return.
          </li>
          <li>
            <strong>Refund Timeline:</strong> Once the product is collected (if
            a reverse pickup is required), your refund will be processed within
            30 days.
          </li>
        </ol>

        <strong>Refund Process</strong>
        <br />
        <span>
          We strive to make the refund process as smooth and efficient as
          possible. If your return claim is approved, your refund will be
          processed as follows:
        </span>
        <ol>
          <li>
            <strong>Online Wallet Orders:</strong> If you placed your order
            using an online wallet, the refund will be credited back to your
            wallet account.
          </li>
          <li>
            <strong>Cash on Delivery Orders:</strong> If you paid via cash on
            delivery, the refund will be transferred to your bank account via
            fund transfer.
          </li>
          <li>
            For any further inquiries regarding refunds, please feel free to
            reach out to us at{" "}
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=myhealth@pillzer.health"
              target="_blank"
            >
              myhealth@pillzer.health
            </a>
            .
          </li>
        </ol>

        <strong>Cancellation Policy</strong>
        <br />
        <ul>
          <li>
            We understand that sometimes plans change, and you may need to
            cancel your order. Here’s what you need to know:
          </li>
        </ul>
        <span>
          <strong>Customer Cancellation:</strong>

          <ul>
            <li>
              You can cancel your order anytime before it is shipped. Once your
              order has been dispatched, it can no longer be canceled.
            </li>
          </ul>
        </span>

        <span>
          <strong>PILLZER Cancellation:</strong>{" "}
        </span>
        <ul>
          <li>
            {" "}
            In some cases, we may need to cancel your order due to unforeseen
            circumstances. These may include:
          </li>
        </ul>
        <ul>
          <ul>
            <li>
              Product unavailability: If the product or the quantity you ordered
              is out of stock.
            </li>
            <li>
              Pricing errors: In the event of inaccuracies or errors in the
              product’s price.
            </li>
            <li>
              Inappropriate Orders: We reserve the right to cancel orders that
              we deem inappropriate based on specific criteria, such as:
            </li>
            <ul>
              <li>
                Orders placed for commercial resale rather than personal use.
              </li>
              <li>Multiple orders for the same product at the same address.</li>
              <li>Bulk quantity orders for single products.</li>
              <li>Invalid or incomplete shipping address.</li>
              <li>Any form of malpractice detected in the order placement.</li>
            </ul>
          </ul>
          <li>
            If we cancel your order, you will be notified promptly, and there
            will be no cancellation charges levied.
          </li>
        </ul>

        <span>
          We aim to provide you with a seamless and hassle-free shopping
          experience. If you have any questions or concerns about our return,
          refund, or cancellation processes, our customer care team is always
          here to assist you.
        </span>
      </span>

      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <h1 style={{ color: "white", fontSize: "2rem", marginBottom: "10px" }}>
          PillzerHealth
        </h1>
        <span style={{ color: "white", fontSize: "1rem" }}>
          Copyright © 2023, PillzerHealth. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
