import React, { useEffect } from "react";

const PaymentRefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ padding: "20px", paddingTop: "0" }}>
      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "white" }}>
          PILLZER (21ventures Healthtech Private Limited)
          <h2 style={{ color: "white" }}>Payment & Refund Policy</h2>
        </h1>
      </div>
      <span>
        <span>
          <br />
          This Payment and Refund Policy sets out the terms and conditions on
          the basis of which we, company Healthco Limited, a company duly
          incorporated under the provisions of the Companies Act, 2013, having
          its registered office at No 3, 2nd floor, 4th A Cross, Pipeline road,
          vijayanagar, Bengaluru, Karnataka-560040, India (referred as “21VHPL”,
          “PILLZER” “Company”, “we”, “us” or “our”), will process payments and
          refunds for transactions carried on Pillzer and PillzerX
          Platforms.21VHPL may assign, transfer, and subcontract its rights
          and/or obligations under this Payment and Refund Policy to any third
          party, as it may deem fit, and you shall continue to be bound by this
          Payment and Refund Policy in the event of such assignment or transfer.
          Acceptance of this Payment and Refund Policy will be deemed to be a
          sufficient notice to you or to anyone who agrees to use the aforesaid
          Platform.
        </span>
        <br />
        <span>
          A. Payment
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              You may use any payment service, like UPI, internet banking, or
              debit or credit cards for making payments for availing any of the
              Services over the Platform (“Fees' '). You shall be directed to
              our third-party payment aggregator/gateway partners such as PayU,
              Paytm, PhonePe, CRED Pay, Juspay, Amazon Pay (“Payment
              Aggregator(s)”) once you proceed to make payment of the Fees. Once
              redirected to the Payment Aggregator, you agree to be bound by the
              terms and conditions and privacy policy prescribed by the
              third-party payment gateway/aggregation partner, basis which the
              transaction shall take place. We will share your details with the
              Payment Aggregator as per the terms of our privacy policy, and the
              same shall be processed in accordance with the respective Payment
              Aggregator’s privacy policy as may be made available to you by
              such Payment Aggregator. For the removal of doubts, it is
              clarified that we neither directly nor indirectly charge any
              amount for Tokenization services. Any update in pricing of the
              Services over the Platform shall not be deemed to be construed any
              charge for tokenization services.
            </li>
            <li>
              21VHPL shall not be liable in any manner whatsoever if incorrect
              or inaccurate details (including those concerning credit or debit
              cards) are provided by you for making the payment of the Fees, or
              if you use a credit or debit card which is not lawfully owned by
              you or if you permit a third party to use your password or any
              other means of accessing your bank account or credit card or debit
              card. Please note that neither we nor the Payment Aggregator will
              be monitoring your use of any payment channel, and you are
              required to use the payment modes at your sole option and risks.
            </li>
            <li>
              The Payment Aggregator may block any card number, account numbers,
              group of cards, or transactions from any specific blocked or
              blacklisted customer cards, accounts, group of IP addresses,
              devices, geographic locations as deemed fit.
            </li>
            <li>
              The Payment Aggregator and banks reserve the right to reject
              payments with respect to any order including for suspicious and
              unlawful transactions, potential or actual fraud, suspicious
              activities, card authentication, and authorization issues,
              overpayment made due to mathematical errors, or any other issue.
            </li>
            <li>
              21VHPL shall keep the financial information as referred to in the
              company Privacy Policy (“Privacy Policy”) confidential at all
              times and shall not utilize and share the same with any of the
              third parties unless it is a third party consented to by you
              and/or required by law, regulation, or court order or as per the
              terms of this refund policy.
            </li>
            <li>
              21VHPL shall not be liable for the rejection of any authorization
              request placed by you for payment of the Fees, by any bank,
              payment gateway, Payment Aggregator, or other relevant third
              party, for any reason including but not limited to risk
              management, suspicion of fraudulent, illegal, or doubtful
              transactions, use of blacklisted/banned cards or pursuant to
              applicable laws, rules or regulations or any instructions from (or
              contained in) any guidelines or instructions by the Reserve Bank
              of India or any internal policies, etc., of such bank, payment
              gateway, or other relevant third party.
            </li>
            <li>
              If you visit or are redirected to Pillzer website at or the
              Pillzer Pharmacy Interface, you agree and acknowledge that 21VHPL
              is not involved in, in any manner whatsoever, and does not
              facilitate:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  The payments for products sold over Pillzer Platforms and that
                  the same is facilitated solely by Pillzer and/or any third
                  party entities (including payment aggregators) engaged by
                  Pillzer for such purpose;
                </li>
                <li>
                  The invoicing of products sold over the Pillzer Platforms; and
                </li>
                <li>
                  The prescription validation undertaken on the Pillzer
                  Platforms by Pillzer and/or any third party entities engaged
                  by Pillzer for such purpose.
                </li>
              </ul>
            </li>
            <li>Minimum payment per transaction must be Rs.500</li>
          </ul>
        </span>
        <span>
          B. Specific Payment Modes
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              Please note that whenever you choose to utilize any mode of
              payment, you must fulfill the terms and conditions, as applicable
              to that mode of payment.
            </li>
            <li>
              Specifically for QR code payment, you note that:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>QR Code is provided by the Payment Aggregator(s).</li>
                <li>
                  You can make payments through UPI or a valid card by scanning
                  the QR Code correctly through any of the payment apps
                  available with you and entering the correct amount.
                </li>
                <li>
                  Your data will be processed in accordance with the relevant
                  Payment Aggregator’s privacy policy and terms, which you
                  should comply with, as applicable.
                </li>
              </ul>
            </li>
            <li>
              Tokenized Card Payments/ Services: Please note that in accordance
              with directions of the Reserve Bank of India, we are not
              authorized to store your card details. For the ease of
              facilitation of card payments, the relevant Payment Aggregator may
              provide you with the option of tokenization of your card data
              pursuant to which your card details will be
              stored/collected/received securely with or to the authorized card
              networks (such as Visa, Mastercard, RuPay, etc.) and
              processed/managed/transferred for making payment of Fees upon you
              selecting card payment as a payment mode. In order for us and the
              relevant Payment Aggregator to facilitate payments through
              tokenized card details, you hereby agree and accept the following
              terms and conditions:
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  Upon you choosing card payments as a payment mode, you will be
                  given an option to securely pay using the tokenized card
                  details;
                </li>
                <li>
                  The relevant Payment Aggregator may reserve the right to
                  disclose your data or information if such data is required or
                  requested to be disclosed by the Payment Aggregator under
                  applicable laws, by card networks or the issuer banks, or
                  under regulatory requirements or investigations, and you
                  hereby give your express consent for such disclosure;
                </li>
                <li>
                  The provision of token of your card details (where the
                  expression token(s) wherever used includes token reference
                  number, card reference number or any other similar term) shall
                  be initiated with your explicit consent through an additional
                  factor of authentication, which shall be facilitated by the
                  relevant Payment Aggregator;
                </li>
                <li>
                  You will have the option to register and deregister your cards
                  for a particular use and for any such request to de-register
                  your card, you should visit "Manage Payments" section under
                  profile options of our Website/App;
                </li>
                <li>
                  You will have the option to use any card registered under the
                  tokenization services;
                </li>
                <li>
                  You will have the option to immediately suspend,
                  delete/deregister or resume any token and for any such
                  request, you should visit "Manage Payments" section under
                  profile options of our Website/App;
                </li>
                <li>
                  You agree that tokenization service is dependent on mandates,
                  instructions, policy and guidelines of Card Networks, Reserve
                  Bank of India, Banks and/or other regulatory authorities as
                  may be relevant from time to time and our continued agreement
                  with Payment Aggregator offering tokenization services. Any
                  changes in mandates, instructions, regulation, guidelines or
                  policies or the change/termination of agreement between us and
                  Payment Aggregators might lead to termination of tokenization
                  services or might require further amendments/addition to this
                  Agreement and you hereby agree to give effect to such
                  mandates, instructions, regulation, policy and guidelines.
                </li>
              </ul>
            </li>
          </ul>
        </span>
        <span>
          C. Cancellation of Lab Tests
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              You may cancel an order for diagnostic tests placed by you at any
              point 2 hours prior to your scheduled sample collection and
              reschedule as per your convenience.
            </li>
            <li>
              Payments for diagnostic tests made directly on the Platform will
              be processed as provided in the ‘Refunds’ section below.
            </li>
            {/* Add other list items */}
          </ul>
        </span>
        <span>
          D. Cancellation / Rescheduling / Booking of Medical Consultations
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              Consultations can be booked, rescheduled or cancelled free of cost
              up to fifteen minutes prior to the start of the appointment for an
              audio / video / chat-based consultation (“Online Medical
              Consultation”) and up to thirty minutes prior to the start of the
              appointment for a physical consultation. You are allowed to
              reschedule an Online Medical Consultation up to a maximum of three
              times for free and are allowed to reschedule a physical
              consultation once for free.
            </li>
            <li>
              Payments for Online Medical Consultation or physical consultation
              made directly on the Platform will be processed as provided in the
              ‘Refunds’ section below.
            </li>
          </ul>
        </span>
        <span>
          E. Refunds
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              Refunds for all eligible cancellations are issued through the
              payment method used at the time of purchase, except for cash
              payments made under the Pay on Delivery mode of payment.
            </li>
            <li>
              You will not receive cash refunds in connection with a card sale.
            </li>
            <li>
              Refunds cannot be processed to third-party accounts. The name on
              your pillzer account should match with the name of the bank
              account holder.
            </li>
            <li>
              Refunds will only be made against genuine and trackable
              cancellation requests generated by you by clicking on the
              cancellation button provided in the App’s user dashboard in your
              pillzer account. Refund request against delays, nature of advice
              obtained, efficacy of treatment, health outcomes of consultation
              will not be entertained.
            </li>
            <li>
              Refunds may be processed within 15 working days from the receipt
              of a request from you. No refund for any eligible cancellation
              will be processed beyond 6 months of placing a cancellation or
              return request. However, in exceptional circumstances, you may
              claim a refund beyond this stipulated period at the sole
              discretion of the company. The refunds for such exceptional
              circumstances will be in the form of “health credits” that you may
              be able to use for the purchase of goods and services from the
              same company.
            </li>
            <li>
              21VHPL / third party sellers on the Platform reserves the right to
              modify / implement a new pricing structure at any time prior to
              billing you for your initial payment of the Fees or for future
              payments of the Fees.
            </li>
          </ul>
        </span>
        <span>
          F. Refund Timelines
          <ul style={{ listStyleType: "lower-roman" }}>
            <li>
              The time frame for different payment modes is typically 5-7
              business days after the cancellation request has been received and
              verified by the company, subject to sub-clause (ii) below.
            </li>
            <li>
              Refund timelines depend on bank turnaround times and RBI
              guidelines. This may change from time to time. Business days shall
              mean any day of the week (excluding Saturdays, Sundays, and public
              holidays) on which commercial banks in India are open for
              business. Considering limited manpower at banks due to COVID-19,
              refund timelines might vary.
            </li>
            <li>
              For orders placed using Pay on Delivery as the payment method,
              refunds can be credited within 7 working days to your bank account
              via National Electronic Funds Transfer (NEFT) or in the form of
              health credits. There will be no cash refund.
            </li>
            <li>
              You will need to update the following information to enable us to
              process a refund to your account:
              <ul style={{ listStyleType: "none" }}>
                <li>The Bank Account Number</li>
                <li>IFSC Code</li>
                <li>Account Holder's Name</li>
              </ul>
            </li>
            <li>
              To the extent permitted by applicable law, the company will not be
              liable for any delay caused in refunds due to delay by third-party
              affiliates (including banks), in providing information by the
              customer, technical issues, and other reasons beyond its control.
            </li>
          </ul>
        </span>
      </span>

      <div
        style={{
          backgroundColor: "rgb(36 50 93)",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <h1 style={{ color: "white" }}>PillzerHealth</h1>

        <span style={{ color: "white" }}>
          Copyright © 2023, PillzerHealth. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default PaymentRefundPolicy;
